@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,400;0,6..12,600;0,6..12,800;1,6..12,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}

.overflowRoot {
  overflow: hidden;
}
  
body {
  margin: 0;
}

.high-zindex {
    z-index: 1333 !important;
}

a{text-decoration: none;}
.main{padding: 2rem 0;}
.main-inner{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    max-width: 80vw;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    align-items: center;
    gap:2rem;
}
article{background-color: black;}
.heading{position: relative; margin: 0 2.5rem;}
.heading::after{
    content: "";
    position: absolute;
    top: 50%;
    width: 72px;
    height: 5px;
    background-color: black;
    margin-left: .45rem;
}
.card{
	min-height: 40vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 1px 3px 7px 3px #af202029;
	opacity: 0;
}

.img-box{
    width: 180px;
    height: auto;
    padding: 3rem 0;
    transition: .5s;
}
.s-name{
    position: absolute;
    left: -25%;
    top: 0;
    width: 3.5rem;
    height: 100%;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    letter-spacing: 2px;
    text-align: center;
    background-color: var(--dark-color);
    color: var(--main);
    font-weight: bold;
    transition: .5s;
}
.icons{font-size: 1.5rem; color: var(--dark-color);}
.icons:hover{color: var(--main);}
.price-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    transition: .5s;
}
.sprice{display: block; text-align: center;}
.cprice{color: var(--main); margin-bottom: .25rem;}
.price{font-weight: bold;}
.card:hover .s-name{left: 0;}
.card:hover .img-box{
    margin-left: 3.5rem;
}

.card:hover {
transition: .5s;
opacity: 0.6;

}
.card:hover .price-box{
    margin-left: 3.5rem;
    padding: 0 1.5rem;
}
@media screen and (min-width: 1200px){
    body{margin:3rem 0 0 0;}
    .heading{margin: 0 5rem;}
    .main-inner{margin-left:auto; margin-right:auto;}
}

section {
    width: 100%;
    height: 100vh;
    /*position: absolute;
    top: 0;
    left: 0;
    overflow: hidden !important;	*/
}

section video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section .navigation {
    position: absolute;
    bottom: 20vh;
    left: 50vw;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

section .navigation li {
    list-style: none;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 4px;
    background: #fff;
    padding: 3px 3px 0;
    opacity: 0.7;
    transition: 0.5s;
}

section .navigation li:hover {
    opacity: 1;
}


section .navigation li img {
    min-width: 20vw;
    max-width: 20vw;
    transition: 0.5s;
}

section .navigation li img:hover {
    width: 200px;
}


.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid white;
}

/*
.popup {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 80vw;
}


*/
.timeline {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
  }
  .timeline li {
	flex: 1;
	position: relative;
	text-align: center;
	/* padding: 1em 0; */
	border-top: 1px solid #ddd;
	cursor: grab;

  }
  .timeline li:hover {
	color: #82bbe9;
	background-color: aqua;
  }
  /*
  .timeline li::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 2em;
	height: 2em;
	border-radius: 50%;
	background-color: #333;
  }
  .timeline li:first-child::before {
	left: 0;
	transform: translateX(0);
  }
  .timeline li:last-child::before {
	left: 100%;
	transform: translateX(-100%);
  }
  */
  .timeline li div {
	position: absolute;
	/*top: 2em;*/
	left: 50%;
	transform: translateX(-50%);
	/*width: 10em;*/
	text-align: center;
	background-color: #f9f9f9;
	padding: 0.5em;
	border-bottom-right-radius: 0.55em;
    border-bottom-left-radius: 0.55em;
  }
  .timeline li time {
	color: #999;
	font-size: 0.8em;
  }

 

  .MenuAcceuil {
	z-index: 1000;
	box-shadow: 1px 3px 7px 3px #af202029;
    position: absolute;
    top: 40vh;
	left: 50%;
	transform: translateX(-50%);
	background-color: #4CAF50; /* Green background */
	border: none; /* Remove border */
	color: white; /* White text */
	padding: 10px 20px; /* Some padding */
	text-align: center; /* Center-aligned text */
	text-decoration: none; /* Remove underline */
	display: inline-block; /* Display as inline-block */
	font-size: 16px; /* Set font size */
	margin: 10px; /* Add some margin */
	cursor: pointer; /* Change cursor to pointer on hover */
	border-radius: 4px; /* Add rounded corners */
	transition: background-color 0.3s ease; /* Add transition effect on hover */
	/*border-style: solid;
	border-color: rgb(0, 102, 128); */
	background-color: #4CAF50;
	color: white;
	padding: 10px 20px;
	border: 4px solid transparent;
	border-image: linear-gradient(to right, #6d7fcc, #f5576c) 1 100%;
	font-size: 26px;
	cursor: pointer;
	transition: all 0.3s ease;	
  }
  
  .MenuAcceuil:hover {
	background-color: #3e8e41; /* Darken background color on hover */
	border-image: linear-gradient(to right, #6d7fcc, #f5576c) 1 0%;
  }

  
	/* The side navigation menu */
	.infosidenav {
		 /* height: 20vh; */ /* 100% Full-height */
		width: 40vw; /* 0 width - change this with JavaScript */
		position: fixed; /* Stay in place */
		z-index: 1; /* Stay on top */
		/*top: 1vh;*/
		right: 1vw;
		overflow: hidden;
		padding-top: 10px; /* Place content 60px from the top */
		transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
		/* background-color: #7386d5; */
	  }

	  .info-sidebar-header {
		background: #6d7fcc;
	  }
	  
	  .info-sidebar-header h3 {
		color: #fff;
		padding: .2em;
	  }
	  .info-side-menu {
		height: 10vh; /*calc(100vh - 130px); */
		overflow-x: auto;
	  }
	  
	  .info-side-menu::-webkit-scrollbar {
		width: 10px;
	  }
	  
	  .info-side-menu::-webkit-scrollbar-thumb {
		background: #5466b3;
		border-radius: 3px;
	  }
	  
	  .info-side-menu::-webkit-scrollbar-thumb:hover {
		background: #3a4c99;
	  }


	/* The side navigation menu */
	.sidenav {
	  height: 100%; /* 100% Full-height */
	  width: 0; /* 0 width - change this with JavaScript */
	  position: fixed; /* Stay in place */
	  z-index: 1; /* Stay on top */
	  top: 0; /* Stay at the top */
	  left: 0;
	 /* background-color: #111; */
	  overflow-x: hidden; /* Disable horizontal scroll */
	  padding-top: 10px; /* Place content 60px from the top */
	  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
	  background-color: #7386d5;
	}

	/* The navigation menu links */
	.sidenav a {
	  padding: 8px 8px 8px 32px;
	  text-decoration: none;
	  font-size: 25px;
	  color: #FFF;
	  display: block;
	  transition: 0.3s;
	}


	.sidenav ul p {
		color: #fff;
		padding: 10px;
	  }
	  

	/* When you mouse over the navigation links, change their color */
	.sidenav a:hover {
	  color: #e98282;
	}

	/* Position and style the close button (top right corner) */
	.sidenav .closebtn {
	  position: absolute;
	  top: 0;
	  right: 25px;
	  font-size: 36px;
	  margin-left: 50px;
	}

	/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
	#main {
	  transition: margin-left .5s;
	  padding: 20px;
	}

	/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
	@media screen and (max-height: 450px) {
	  .sidenav {padding-top: 15px;}
	  .sidenav a {font-size: 18px;}
	}

	h1 {
	  text-align: center;
	  font-family: Tahoma, Arial, sans-serif;
	  color: #06D85F;
	  margin: 80px 0;
	}

	.box {
	  width: 40%;
	  margin: 0 auto;
	  background: rgba(255,255,255,0.2);
	  padding: 35px;
	  border: 2px solid #fff;
	  border-radius: 20px/50px;
	  background-clip: padding-box;
	  text-align: center;
	  display:none;
	}

	.button {
	  font-size: 1em;
	  padding: 10px;
	  color: #fff;
	  border: 2px solid #06D85F;
	  border-radius: 20px/50px;
	  text-decoration: none;
	  cursor: pointer;
	  transition: all 0.3s ease-out;
	}
	.button:hover {
	  background: #06D85F;
	}     

	.overlay {
	  position: fixed;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  background: rgba(0, 0, 0, 0.7);
	  transition: opacity 500ms;
	  visibility: hidden;
	  opacity: 0;
	  z-index: 99999;
	}
	.overlay:target {
	  visibility: visible;
	  opacity: 1;
	}

	.popup {
	  margin: 10px auto;
	  padding: 20px;
	  background: #fff;
	  border-radius: 5px;
	 /* width: 30%; */
	 /* height: 70%; */
	  position: relative;
	  transition: all 5s ease-in-out;
	}

	.popup h2 {
	  margin-top: 0;
	  color: #333;
	  font-family: Tahoma, Arial, sans-serif;
	}
	.popup .close {
	  position: absolute;
	  top: 20px;
	  right: 30px;
	  transition: all 200ms;
	  font-size: 30px;
	  font-weight: bold;
	  text-decoration: none;
	  color: #333;
	}
	.popup .close:hover {
	  color: #06D85F;
	}
	.popup .content {
	  max-height: 95%;
	  overflow: auto;
	  margin: auto;
	}

	.popup button {
		background-color: #4CAF50;
		color: white;
		border: none;
		padding: 10px 20px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
		margin: 10px 0;
		cursor: pointer;
		border-radius: 4px;
	  }
	  
	p,
	label {
	    font: 1rem 'Fira Sans', sans-serif;
	}

	input {
	    margin: 0.4rem;
	}
  

	.sidebar {
		min-width: 250px;
		max-width: 250px;
		background: #7386d5;
		color: #fff;
		margin-left: -250px;
		transition: all 0.5s;
	  }
	  
	  .sidebar.is-open {
		margin-left: 0;
		transition: 0.5s;
	  }
	  
	  .sidebar-header {
		background: #6d7fcc;
	  }
	  
	  .sidebar-header h3 {
		color: #fff;
		padding: 1em;
	  }
	  
	  .sidebar ul p {
		color: #fff;
		padding: 10px;
	  }
	  
	  .menu-open {
		background: #6d7fcc;
	  }
	  
	  .nav-item:hover {
		color: #7386d5;
		background: #fff;
	  }
	  
	  .items-menu {
		color: #fff;
		background: #6d7fcc;
	  }
	  
	  li a.dropdown-toggle::after {
		display: inline-flex;
		position: relative;
		left: 60%;
		top: 10%;
	  }
	  
	  .sidebar-header > span {
		position: relative;
		float: right;
		margin: 0.5em;
		font-size: 2rem;
		cursor: pointer;
		display: none;
	  }
	  .side-menu {
		height: calc(100vh - 130px);
		overflow-y: scroll;
	  }
	  
	  .side-menu::-webkit-scrollbar {
		width: 10px;
	  }
	  
	  .side-menu::-webkit-scrollbar-thumb {
		background: #5466b3;
		border-radius: 3px;
	  }
	  
	  .side-menu::-webkit-scrollbar-thumb:hover {
		background: #3a4c99;
	  }

 


	/* The side navigation menu */
	.sidenavRight {
          width: 0;
          opacity: 0;	
	  height: 100%; /* 100% Full-height */
	  position: fixed; /* Stay in place */
	  z-index: 1; /* Stay on top */
	  top: 0; /* Stay at the top */
	  right: 0;
	 /* background-color: #111; */
	  overflow-x: hidden; /* Disable horizontal scroll */
	  padding-top: 10px; /* Place content 60px from the top */
	  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenavRight */
	  background-color: #7386d5;
	}

	/* The navigation menu links */
	.sidenavRight a {
	  padding: 8px 8px 8px 32px;
	  text-decoration: none;
	  font-size: 25px;
	  color: #FFF;
	  display: block;
	  transition: 0.3s;
	}


	.sidenavRight ul p {
		color: #fff;
		padding: 10px;
	  }
	  

	/* When you mouse over the navigation links, change their color */
	.sidenavRight a:hover {
	  color: #e98282;
	}

	/* Position and style the close button (top right corner) */
	.sidenavRight .closebtn {
	  position: absolute;
	  top: 0;
	  right: 25px;
	  font-size: 36px;
	  margin-left: 50px;
	}

	/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
	#main {
	  transition: margin-left .5s;
	  padding: 20px;
	}

	/* On smaller screens, where height is less than 450px, change the style of the sidenavRight (less padding and a smaller font size) */
	@media screen and (max-height: 450px) {
	  .sidenavRight {padding-top: 15px;}
	  .sidenavRight a {font-size: 18px;}
	}




.loader-fb {
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  position: absolute !important;
}
/* Demo specific styles end */

/* Loader with three blocks */
.loader-fb, .loader-fb:before, .loader-fb:after {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 50px;
  background-color: rgba(215,230,240,0.9);
  border: 1px solid rgb(215,230,240);
  z-index: 100000;
  content: ' ';
  margin-left: -5px;
  margin-right: -9px;
}
.loader-fb:before {
  top: -11px;
  left: -100%;
  animation: loading-fb .8s cubic-bezier(.4,.5,.6,1) infinite;
}
.loader-fb {
  animation: loading-fb-main .8s cubic-bezier(.4,.5,.6,1) .2s infinite;
}
.loader-fb:after {
  top: -11px;
  right: -100%;
  margin-top: 50%;
  animation: loading-fb .8s cubic-bezier(.4,.5,.6,1) .4s infinite;
}
@keyframes loading-fb {
  from {
    transform: scaleY(1.4);
    background-color: rgba(55,114,171,0.9);
    border: 1px solid rgb(55,114,171);
  }
}
@keyframes loading-fb-main {
  from {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -10px;
    background-color: rgba(55,114,171,0.9);
    border: 1px solid rgb(55,114,171);
  }
}

.containerSubscibe {
	max-width: 800px;
	margin: 0 auto;
	text-align: center;
	font-size: xx-large;
  }
.containerSubscibe  form {
	display: inline-block;
  }
.containerSubscibe  input[type="email"] {
	padding: 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 3px;
	width: 250px;
  }
.containerSubscibe  input[type="submit"] {
	padding: 10px 20px;
	font-size: 16px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	margin-left: 10px;
  }
.containerSubscibe  input[type="submit"]:hover {
	background-color: #0056b3;
  }

.containerContact {
	max-width: 800px;
	margin: 0 auto;
	text-align: center;
	font-size: xx-large;
  }
.containerContact  .icon {
	width: 48px;
	height: 48px;
	margin: 10px;
  }
.containerContact  .icon-link {
	display: inline-block;
	text-decoration: none;
  }

.hotspotInfo {
	/*border: dotted lightyellow;-*/
	border-radius: 50%;
	padding: 2.5px;
	background-clip: content-box;
	animation: flash 5s linear infinite;
	cursor: pointer;
  }
  
  @keyframes flash {
	 0%, 50%, 100% {
		opacity: 1;
	 }
	 25%, 75% {
		opacity: 0;
	 }
  }
